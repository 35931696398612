import React, { useEffect } from 'react'
import * as s from "./main.module.css"

// import useSize from '../utils/useSize';

import { gsap } from "gsap/dist/gsap";

import "animate.css/animate.min.css";

import { withpath } from '../utils/index.js'

import Art2 from '../assets/art2.svg';
import Art5 from '../assets/art5.svg';
import Art8 from '../assets/art8.svg';

import { Waypoint } from 'react-waypoint';

const Main = (p) => {

	const data = p.data
  const waypercent = "20%"
  const mainduration = 1.3
  const shortduration = .8

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});

	useEffect(() => {

		let art1img = document.querySelectorAll(`#art1img`);
		gsap.killTweensOf([art1img]);
    gsap.set(art1img, {opacity: 1, scale:.7});
    
    let art2img = document.querySelectorAll(`#art2img`);
    let art21 = document.querySelectorAll(`#art21`);
    let art22 = document.querySelectorAll(`#art22`);
    let art2txt = document.querySelectorAll(`#art2txt`);
		gsap.killTweensOf([art2img, art21, art22, art2txt]);
		gsap.set([art2img, art21, art22], {opacity: 0, scale:.4});
    gsap.set(art2txt, {opacity: 0, translateY: -20});
    
    let art3img = document.querySelectorAll(`#art3img`);
    let art31 = document.querySelectorAll(`#art31`);
		gsap.killTweensOf([art3img, art31]);
    gsap.set([art3img], {opacity: 0, scale:.7});
    gsap.set([art31], {opacity: 0, scale:.4});

    let art4img = document.querySelectorAll(`#art4img`);
    let art41 = document.querySelectorAll(`#art41`);
		gsap.killTweensOf([art4img, art41]);
    gsap.set([art4img], {opacity: 0, scale:.7});
    gsap.set([art41], {opacity: 0, scale:.4});

    let art5img = document.querySelectorAll(`#art5img`);
    let art5bar1 = document.querySelectorAll(`#a5-bar1`);
    let art5bar2 = document.querySelectorAll(`#a5-bar2`);
    let art5txt1 = document.querySelectorAll(`#a5-txt1`);
    let art5txt2 = document.querySelectorAll(`#a5-txt2`);
    let art5txt3 = document.querySelectorAll(`#a5-txt3`);
    let art5txt4 = document.querySelectorAll(`#a5-txt4`);
    let art5txt5 = document.querySelectorAll(`#a5-txt5`);
    let art5arrow = document.querySelectorAll(`#a5-arrow`);
		gsap.killTweensOf([art5img, art5bar1, art5bar2, art5txt1, art5txt2, art5txt3, art5txt4, art5txt5, art5arrow]);
    gsap.set([art5img], {opacity: 0, scale:.4});
    gsap.set([art5bar1, art5bar2], {opacity: 0, scaleY:0, transformOrigin: 'bottom'});
    gsap.set([art5txt1, art5txt2, art5txt3, art5txt4, art5txt5, art5arrow], {opacity: 0, translateY: 16});
    
    let art6img = document.querySelectorAll(`#art6img`);
		gsap.killTweensOf([art6img]);
    gsap.set([art6img], {opacity: 0, scale:.4});

    let art7img = document.querySelectorAll(`#art7img`);
    let art71 = document.querySelectorAll(`#art71`);
		gsap.killTweensOf([art7img, art71]);
    gsap.set([art7img, art71], {opacity: 0, scale:.4});

    let art8img = document.querySelectorAll(`#art8img`);
    let art81 = document.querySelectorAll(`#art81`);
    let art82 = document.querySelectorAll(`#art82`);
    let art8txt = document.querySelectorAll(`#art8txt`);
		gsap.killTweensOf([art8img, art81, art82, art8txt]);
    gsap.set([art8img, art81, art82], {opacity: 0, scale:.4});
    gsap.set(art2txt, {opacity: 0, translateY: -20});


  },[])
  

  /* function enterP(){
		
	}

	function leaveP(){
		let person1img = document.querySelectorAll(`#person1-img`);
    let person1name = document.querySelectorAll(`#person1-name`);
    let person2img = document.querySelectorAll(`#person2-img`);
    let person2name = document.querySelectorAll(`#person2-name`);

		gsap.killTweensOf([person1img,person1name,person2img,person2name]);

    let tl = gsap.timeline({ paused: false });
      tl.to([person1img,person1name,person2img,person2name], {opacity: 0, translateY: -20, duration: .4, stagger: .07, ease: `power1.out` }, 0.0);
	} */


	function enter1(){
    console.log('Entering 1');
		let art1img = document.querySelectorAll(`#art1-img`);
		
    gsap.killTweensOf([art1img]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art1img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
	}

	function leave1(){
    console.log('Exiting 1');
		let art1img = document.querySelectorAll(`#art1-img`);

		gsap.killTweensOf([art1img]);

		let tl = gsap.timeline({ paused: false });
    tl.to([art1img], {opacity: 0, scale: .7, duration: .2, ease: `power1.out` });
	}


	function enter2(){
    let art2img = document.querySelectorAll(`#art2img`);
		let art21 = document.querySelectorAll(`#art21`);
    let art22 = document.querySelectorAll(`#art22`);
    let art2txt = document.querySelectorAll(`#art2txt`);

		gsap.killTweensOf([art2img, art21, art22, art2txt]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art2img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out`}, 0.0)
			tl.to(art21, {opacity: 1, scale: 1, duration: shortduration, ease: `power2.out`}, 0.1)
			tl.to(art22, {opacity: 1, scale: 1, duration: shortduration, ease: `power2.out`}, 0.2);
			tl.to(art2txt, {opacity: 1, translateY: 0, duration: shortduration, ease: `power2.out`}, 0.3);
	}

	function leave2(){
		let art2img = document.querySelectorAll(`#art2img`);
		let art21 = document.querySelectorAll(`#art21`);
    let art22 = document.querySelectorAll(`#art22`);
    let art2txt = document.querySelectorAll(`#art2txt`);

    gsap.killTweensOf([art2img, art21, art22, art2txt]);

		let tl = gsap.timeline({ paused: false });
			tl.to([art2img, art21, art22], {opacity: 0, scale: .4, duration: .2, stagger: .05, ease: `power1.out` }, 0.0);
			tl.to(art2txt, {opacity: 0, translateY: -20, duration: .2, ease: `power1.out` }, 0.0);
	}


	function enter3(){
		let art3img = document.querySelectorAll(`#art3img`);
    let art31 = document.querySelectorAll(`#art31`);
		gsap.killTweensOf([art3img, art31]);

		gsap.killTweensOf([art3img, art31]);

    
		let tl = gsap.timeline({ paused: false });
			tl.to(art3img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` }, 0.0);
			tl.to(art31, {opacity: 1, scale: 1, duration: .7, ease: `power2.out` }, 0.2);
	}

	function leave3(){
		let art3img = document.querySelectorAll(`#art3img`);
    let art31 = document.querySelectorAll(`#art31`);
		gsap.killTweensOf([art3img, art31]);

		gsap.killTweensOf([art3img, art31]);

		let tl = gsap.timeline({ paused: false });
    tl.to([art3img], {opacity: 0, scale: .7, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);
    tl.to([art31], {opacity: 0, scale: .4, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);
  }


  function enter4(){
    let art4img = document.querySelectorAll(`#art4img`);
    let art41 = document.querySelectorAll(`#art41`);

		gsap.killTweensOf([art4img, art41]);

		let tl = gsap.timeline({ paused: false });
      tl.to(art4img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
      tl.to(art41, {opacity: 1, scale: 1, duration: shortduration, ease: `power2.out` }, 0.3);
	}

	function leave4(){
		let art4img = document.querySelectorAll(`#art4img`);
    let art41 = document.querySelectorAll(`#art41`);

		gsap.killTweensOf([art4img, art41]);

		let tl = gsap.timeline({ paused: false });
    tl.to([art4img], {opacity: 0, scale: .7, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);
    tl.to([art41], {opacity: 0, scale: .4, duration: .2, stagger: .08, ease: `power1.out` }, 0.0);
  }


  function enter5(){
    let art5img = document.querySelectorAll(`#art5img`);
    let art5bar1 = document.querySelectorAll(`#a5-bar1`);
    let art5bar2 = document.querySelectorAll(`#a5-bar2`);
    let art5txt1 = document.querySelectorAll(`#a5-txt1`);
    let art5txt2 = document.querySelectorAll(`#a5-txt2`);
    let art5txt3 = document.querySelectorAll(`#a5-txt3`);
    let art5txt4 = document.querySelectorAll(`#a5-txt4`);
    let art5txt5 = document.querySelectorAll(`#a5-txt5`);
    let art5arrow = document.querySelectorAll(`#a5-arrow`);

		gsap.killTweensOf([art5img, art5bar1, art5bar2, art5txt1, art5txt2, art5txt3, art5txt4, art5txt5, art5arrow]);

		let tl = gsap.timeline({ paused: false });
      tl.to(art5img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
      tl.to(art5txt1, {opacity: 1, translateY: 0, duration: shortduration, ease: `power1.out` }, .1);
      tl.to(art5bar1, {opacity: .8, scaleY: 1, duration: shortduration, ease: `power1.out` }, 0.1);
      tl.to(art5txt2, {opacity: 1, translateY: 0, duration: shortduration, ease: `power1.out` }, .2);
      tl.to(art5arrow, {opacity: 1, translateY: 0, duration: shortduration, ease: `power1.out` }, 0.3);
      tl.to(art5txt3, {opacity: 1, translateY: 0, duration: .5, ease: `power1.out` }, 0.5);
      tl.to(art5bar2, {opacity: .8, scaleY: 1, duration: shortduration, ease: `power1.out` }, 0.5);
      tl.to(art5txt4, {opacity: 1, translateY: 0, duration: .5, ease: `power1.out` }, 0);
      tl.to(art5txt5, {opacity: 1, translateY: 0, duration: shortduration, ease: `power1.out` }, 0.5);
  }
  
  function leave5(){
		let art5img = document.querySelectorAll(`#art5img`);
    let art5bar1 = document.querySelectorAll(`#a5-bar1`);
    let art5bar2 = document.querySelectorAll(`#a5-bar2`);
    let art5txt1 = document.querySelectorAll(`#a5-txt1`);
    let art5txt2 = document.querySelectorAll(`#a5-txt2`);
    let art5txt3 = document.querySelectorAll(`#a5-txt3`);
    let art5txt4 = document.querySelectorAll(`#a5-txt4`);
    let art5txt5 = document.querySelectorAll(`#a5-txt5`);
    let art5arrow = document.querySelectorAll(`#a5-arrow`);

		gsap.killTweensOf([art5img, art5bar1, art5bar2, art5txt1, art5txt2, art5txt3, art5txt4, art5txt5, art5arrow]);
    
    let tl = gsap.timeline({ paused: false });
    tl.to([art5img], {opacity: 0, scale: .7, stagger: .08, ease: `power1.out` }, 0.0);
    tl.to([art5bar1, art5bar2], {opacity: 0, scaleY: 0, stagger: .08, transformOrigin: 'bottom', ease: `power1.out` }, 0.0);
    tl.to([art5txt1, art5txt2, art5txt3, art5txt4, art5txt5, art5arrow], {opacity: 0, translateY: -12, stagger: .08, transformOrigin: 'bottom', ease: `power1.out` }, 0.0);
  }


  function enter6(){
    let art6img = document.querySelectorAll(`#art6img`);

		gsap.killTweensOf([art6img]);

		let tl = gsap.timeline({ paused: false });
      tl.to(art6img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
  }
  
  function leave6(){
		let art6img = document.querySelectorAll(`#art6img`);

		gsap.killTweensOf([art6img]);

    let tl = gsap.timeline({ paused: false });
    tl.to([art6img], {opacity: 0, scale: .7, stagger: .08, ease: `power1.out` }, 0.0);
  }

  function enter7(){
    let art7img = document.querySelectorAll(`#art7img`);
    let art71 = document.querySelectorAll(`#art71`);

    gsap.killTweensOf([art7img, art71]);
    
    let tl = gsap.timeline({ paused: false });
      tl.to(art7img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
      tl.to(art71, {opacity: 1, scale: 1, duration: shortduration, ease: `power2.out` }, .35);
    
  }
  
  function leave7(){
    let art7img = document.querySelectorAll(`#art7img`);
    let art71 = document.querySelectorAll(`#art71`);

    gsap.killTweensOf([art7img, art71]);
    
    let tl = gsap.timeline({ paused: false });
    tl.to([art7img], {opacity: 0, scale: .7, stagger: .08, ease: `power1.out` }, 0.0);
    tl.to([art71], {opacity: 0, scale: .4, stagger: .08, ease: `power1.out` }, 0.0);
  }

  function enter8(){
    let art8img = document.querySelectorAll(`#art8img`);
    let art81 = document.querySelectorAll(`#art81`);
    let art82 = document.querySelectorAll(`#art82`);
    let art8txt = document.querySelectorAll(`#art8txt`);

		gsap.killTweensOf([art8img, art81, art82, art8txt]);

    let tl = gsap.timeline({ paused: false });
      tl.to(art8img, {opacity: 1, scale: 1, duration: mainduration, ease: `power1.out` });
      tl.to(art81, {opacity: 1, scale: 1, duration: shortduration, ease: `power2.out` }, .1);
      tl.to(art82, {opacity: 1, scale: 1, duration: shortduration, ease: `power2.out` }, .2);
      tl.to(art8txt, {opacity: 1, translateY: 0, duration: shortduration, ease: `power2.out`}, 0.4);
  }
  
  function leave8(){
    let art8img = document.querySelectorAll(`#art8img`);
    let art81 = document.querySelectorAll(`#art81`);
    let art82 = document.querySelectorAll(`#art82`);
    let art8txt = document.querySelectorAll(`#art8txt`);

		gsap.killTweensOf([art8img, art81, art82, art8txt]);
    
		let tl = gsap.timeline({ paused: false });
    tl.to([art8img], {opacity: 0, scale: .7, stagger: .08, ease: `power1.out` }, 0.0);
    tl.to([art81, art82], {opacity: 0, scale: .4, stagger: .08, ease: `power1.out` }, 0.0);
    tl.to(art8txt, {opacity: 0, translateY: -20, duration: .1, ease: `power2.out`}, 0.0);
  }
  


	return (
		<section className={s.article} >

      {/* Hero Block */}
			<div 
				className={s.headerbg} 
				style={{backgroundImage: `url(${withpath(`/img/bg.jpg`)})` }}
			>
        <div 
          className={s.headerbox}
          style={{backgroundImage: `url(${withpath(`/img/hero-overlay.png`)})` }}
        >
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
          <div className={s.hr}></div>
					<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
				</div>
			</div>

      {/* Intro */}
			<div className={s.box} data-id={0}>

				<div className={s.tbox} data-id={0}>
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />

          <Waypoint 
            onEnter={(p) => {enter1()}} 
            onLeave={(p) => {leave1()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art1}>
              <div id='art1img'>
                <img src={`${withpath(`/img/art1.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>
				</div>

			</div>


      {/* Unprecedented disruption */}
      <div className={s.box} data-id={1}>
        <div className={s.tbox} data-id={2}>

         <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={0} />

          <Waypoint 
            onEnter={(p) => {enter2()}} 
            onLeave={(p) => {leave2()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art2}>
              <div className={s.art2txt} id='art2txt'>
                <Art2 />
              </div>
              <div className={s.art21} id='art21'>
                <img src={`${withpath(`/img/art2_1.png`)}`} alt='A look at the port from above' />
              </div>
              <div className={s.art22} id='art22'>
                <img src={`${withpath(`/img/art2_2.png`)}`} alt='Worker in the port from above' />
              </div>
              <div className={s.art2img} id='art2img'>
                <img src={`${withpath(`/img/art2.png`)}`} alt='Globe in abstract network' />
              </div>
            </div>
          </Waypoint>

          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={5} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={6} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={8} />

          <Waypoint 
            onEnter={(p) => {enter3()}} 
            onLeave={(p) => {leave3()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art3}>
              <div className={s.art31} id='art31'>
                <img src={`${withpath(`/img/alexandre.png`)}`} alt='Alexandre Tavazzi portrait' />
                <p>
                  Alexandre Tavazzi
                </p>
                <p>
                  Head of CIO Office &#38; Macro Research
                </p>
              </div>
              <div className={s.art3img} id='art3img'>
                <img src={`${withpath(`/img/art3.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>

        </div>
      </div>


      {/* Next-generation technology */}
			<div className={s.box} data-id={1}>

				<div className={s.tbox} data-id={2}>
          <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[8] }} data-id={8} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[9] }} data-id={9} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[10] }} data-id={10} />

          <Waypoint 
            onEnter={(p) => {enter4()}} 
            onLeave={(p) => {leave4()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art4}>
              <div className={s.art41} id='art41'>
                <img src={`${withpath(`/img/art4_1.png`)}`} alt='A person in thinking pose' />
              </div>
              <div className={s.art4img} id='art4img'>
                <img src={`${withpath(`/img/art4.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>
				</div>
			</div>


      {/* Rise of robotics */}
			<div className={s.box} data-id={2}>
				
				<div className={s.tbox} data-id={3}>

          <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[11] }} data-id={11} />
					<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[12] }} data-id={12} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[13] }} data-id={13} />

          <Waypoint 
            onEnter={(p) => {enter5()}} 
            onLeave={(p) => {leave5()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art5}>
              <div className={s.art51} id='art51'>
                <Art5 />
              </div>
              <div className={s.art5img} id='art5img'>
                <img src={`${withpath(`/img/art5.png`)}`} alt='A worker operating an industrial robot illustration' />
              </div>
            </div>
          </Waypoint>

          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[14] }} data-id={14} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[15] }} data-id={15} />


          <Waypoint 
            onEnter={(p) => {enter6()}} 
            onLeave={(p) => {leave6()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art6}>
              <div className={s.art6img} id='art6img'>
                <img src={`${withpath(`/img/art6.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>

				</div>
			</div>


      {/* Blockchain */}
			<div className={s.box} data-id={3}>
				<div className={s.tbox} data-id={4}>

          <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[16] }} data-id={16} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[17] }} data-id={17} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[18] }} data-id={18} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[19] }} data-id={19} />
          <div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[20] }} data-id={20} />


          <Waypoint 
            onEnter={(p) => {enter7()}} 
            onLeave={(p) => {leave7()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art7}>
              <div className={s.art71} id='art71'>
                <img src={`${withpath(`/img/art7_1.png`)}`} alt='A chain made from pixels illustration' />
              </div>
              <div className={s.art7img} id='art7img'>
                <img src={`${withpath(`/img/art7.png`)}`} alt='A network illustration' />
              </div>
            </div>
          </Waypoint>
          
				</div>
			</div>


      {/* Artificial Intelligence */}
			<div className={s.box} data-id={4}>
				<div className={s.tbox} data-id={5}>

          <div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[21] }} data-id={21} />
					<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[22] }} data-id={22} />
					<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[23] }} data-id={23} />

          <Waypoint 
            onEnter={(p) => {enter8()}} 
            onLeave={(p) => {leave8()}} 
            bottomOffset={waypercent} 
            scrollableAncestor={`window`} 
            fireOnRapidScroll={false}
          >
            <div className={s.art8}>
              <div className={s.art8txt} id='art8txt'>
                <Art8 />
              </div>
              <div className={s.art81} id='art81'>
                <img src={`${withpath(`/img/art8_1.png`)}`} alt='A digialized face illustration' />
              </div>
              <div className={s.art82} id='art82'>
                <img src={`${withpath(`/img/art8_2.png`)}`} alt='A cube made from pixels illustration' />
              </div>
              <div className={s.art8img} id='art8img'>
                <img src={`${withpath(`/img/art8.png`)}`} alt='A crane lifting a shipping container illustration' />
              </div>
            </div>
          </Waypoint>


					<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[24] }} data-id={24} />
					<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[25] }} data-id={25} />

        </div>
      </div>


		</section>
)}

export default Main
