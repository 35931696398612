// extracted by mini-css-extract-plugin
export var main = "main-module--main--1FvNJ";
export var article = "main-module--article--2jFsv";
export var headerbg = "main-module--headerbg--16AU3";
export var svgbg = "main-module--svgbg--1Wmkr";
export var headerbox = "main-module--headerbox--SRAzG";
export var hr = "main-module--hr--3ikE5";
export var hicon = "main-module--hicon--1Ovri";
export var h0 = "main-module--h0--NTeF_";
export var desc = "main-module--desc--1EfeX";
export var box = "main-module--box--2G4QO";
export var tbox = "main-module--tbox--2ZzNy";
export var tboxl = "main-module--tboxl--3lvVj";
export var tboxr = "main-module--tboxr--2bXVz";
export var multiart = "main-module--multiart--3txhX";
export var multiartMobile = "main-module--multiartMobile--3ece6";
export var multiartDesktop = "main-module--multiartDesktop--1Zsu3";
export var txt = "main-module--txt--1T3jE";
export var htxt = "main-module--htxt--2apbL";
export var art1 = "main-module--art1--VlUQi";
export var art2 = "main-module--art2--14cYR";
export var art3 = "main-module--art3--1Kgh6";
export var art4 = "main-module--art4--DXfg-";
export var art5 = "main-module--art5--2k8rc";
export var art6 = "main-module--art6--2E7GG";
export var art7 = "main-module--art7--3jD2U";
export var art8 = "main-module--art8--2W-g_";
export var art21 = "main-module--art21--1WTs_";
export var art22 = "main-module--art22--2pI_V";
export var art2txt = "main-module--art2txt--1L_Yb";
export var art2img = "main-module--art2img--2KUI9";
export var art31 = "main-module--art31--33PlL";
export var art3img = "main-module--art3img--GVWcw";
export var art41 = "main-module--art41--2ef3P";
export var art4img = "main-module--art4img--1oPOO";
export var art51 = "main-module--art51--1isew";
export var art5img = "main-module--art5img--3yeE6";
export var art6img = "main-module--art6img--1x66W";
export var art71 = "main-module--art71--5wWAx";
export var art7img = "main-module--art7img--JEcPL";
export var art81 = "main-module--art81--1BWGs";
export var art82 = "main-module--art82--2IHcH";
export var art8txt = "main-module--art8txt--3MqQi";
export var art8img = "main-module--art8img--2tWN_";
export var legend = "main-module--legend--3PbDG";
export var axis = "main-module--axis--y6q9K";
export var source = "main-module--source--29_8s";
export var sources = "main-module--sources--2EMg7";